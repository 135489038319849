
/**
 * border radius
 */

.btn,
.jumbotron,
.nav :hover {
    border-radius: 0px;
}


/* cart badge */
.cart-badge,
.cart-badge:hover {
    border-radius: 50%;
    padding: 2px;
    font-size: 12px;
    font-style: italic;
    background: #000;
}
/**
 * single product page - product name
 */

.name {
    background: indigo;
    color: #fff;
    font-weight: bold;
}


/* black shade form 10-1 */
.black-10 {
    background: #f2f2f2;
}
.black-9 {
    background: #e6e6e6;
}
.black-8 {
    background: #d9d9d9;
}
.black-7 {
    background: #cccccc;
}
.black-6 {
    background: #bfbfbf;
}
.black-5 {
    background: #b3b3b3;
}

/**
 * product image on card
 */

.product-img {
    min-height: 100px;
}

.jumbotron h2 {
    margin-top: -20px;
}

@media only screen and (max-width: 600px) {
    .jumbotron h2 {
        margin-top: 10px;
    }
}

/**
 * jumbotron animation
 */

.jumbotron {
    height: 180px;
    color: #fff;
    background: linear-gradient(-45deg, #52c2ee, #408ece, #23a6d5, #23c6d5);
    background-size: 400% 400%;
    -webkit-animation: Gradient 15s ease infinite;
    -moz-animation: Gradient 15s ease infinite;
    animation: Gradient 15s ease infinite;
}

@-webkit-keyframes Gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

@-moz-keyframes Gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

@keyframes Gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "filters contents contents contents";
  }
  
  .filters { grid-area: filters; min-width: 14rem; max-width: 19rem;}
  
  .contents { grid-area: contents; }

  
.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

.bm-item:hover {
  color: white;
}

.bm-burger-button {
  position: fixed;
  width: 26px;
  height: 20px;
  left: 26px;
  top: 26px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #000;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

