
#forgeViewer{
    height: 600px; 
    width: 100%; 
    position: relative; 
    padding: 0px;
    z-index: 0;
  }


.glyphicon-spin {
    -webkit-animation: spin 1000ms infinite linear;
    animation: spin 1000ms infinite linear;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}
@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

.toolbarButtonUCH {
    background-image: url(../images/gears.png);
    background-size: 36px;
    color: white;
    background-repeat: no-repeat;
    background-position: center;
  }
  